import React from 'react';
import { navigate } from 'gatsby';
import { Header, Icon } from 'semantic-ui-react'

export default ({ happening, height }) => {
  const { type, title, slug, image, color } = happening;

  return (
    <div className="happening-item-container">
      <div className="happening-item-header" style={{ background: color }}>
        <Header as="h5">{type.toUpperCase()}</Header>
      </div>
      <div style={{ backgroundImage: `url('${image}')`, height }} className="happening-item-image" />
      <div className="happening-item-footer" style={{ background: color }}>
        <Header as="h4">{title.toUpperCase()}</Header>
        <button onClick={() => navigate(slug)} className="happening-read-more-button">
          <Header as="h5">READ MORE</Header>
          <Icon name={"long arrow alternate right"} size="large"/>
        </button>
      </div>
    </div>
  )
}