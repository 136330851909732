import React from 'react';
import { navigate } from 'gatsby';
import { Grid, Header, Icon } from 'semantic-ui-react';

export default ({ happening }) => {
  const { type, title, slug, image, color } = happening;
  return (
    <div style={{ backgroundImage: `url('${image}')` }} className="featured-happenings-container">
      <div className="featured-happenings-content-background" style={{ background: color }} />
      <Grid columns={1} className="featured-happenings-content">
        <Grid.Column verticalAlign="middle">
          <Header as="h5">{type.toUpperCase()}</Header>
          <Header as="h1">{title}</Header>
          <button onClick={() => navigate(slug)} className="happening-read-more-button">
            <Header as="h5">READ MORE</Header>
            <Icon name="long arrow alternate right" size="large"/>
          </button>
        </Grid.Column>
      </Grid>
    </div>
  )
}