import React from 'react';
import { Header, Grid, Dropdown, Icon } from 'semantic-ui-react';

import Layout from '../components/Layout';
import FeaturedHappenings from '../components/FeaturedHappenings';
import HappeningItem from '../components/HappeningItem';

import '../style/happenings.css';


const happeningTypes = [
  'all',
  'blog post',
  'event',
  'publicity'
];

const filterOptions = happeningTypes.map(filterType => ({
  key: filterType,
  value: filterType,
  text: filterType.toUpperCase()
}));



export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterChoice: 'all',
      initialHappenings: [],
      currentHappenings: [],
      currentPage: 1,
      rangeLow: 0,
      rangeHigh: 8
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    const { edges } = this.props.data.allAirtable;

    const happenings = edges.map(edge => {
      const { Title, HeroImageURL, HappeningType, OverlayColor, Slug } = edge.node.data;

      return {
        title: Title,
        slug: `happenings/${Slug}`,
        color: OverlayColor,
        image: HeroImageURL,
        type: HappeningType
      }
    });

    this.setState({ currentHappenings: happenings, initialHappenings: happenings })
  }

  onFilterChange(e, { value, name }) {
    const { initialHappenings } = this.state;

    let filteredHappenings = initialHappenings
      .filter(({ type }) => value === type || value === 'all');

    this.setState({
      filterChoice: value,
      currentHappenings: filteredHappenings,
      currentPage: 1,
      rangeLow: 0,
      rangeHigh: 8 > filteredHappenings.length ? filteredHappenings.length : 8
    })
  }

  onPageChange(direction) {
    const { currentPage, rangeLow, rangeHigh, currentHappenings } = this.state;

    if (direction === "left") {
      this.setState({
        currentPage: currentPage - 1,
        rangeLow: rangeLow - 8,
        rangeHigh: rangeLow
      })
    } else {
      this.setState({
        currentPage: currentPage + 1,
        rangeLow: rangeHigh,
        rangeHigh: rangeHigh + 8 > currentHappenings.length ? currentHappenings.length : rangeHigh + 8
      })
    }
  }
  
  render() {
    const {
      currentPage,
      initialHappenings,
      currentHappenings,
      filterChoice,
      rangeHigh,
      rangeLow
    } = this.state;
    return (
      <Layout>
        <Header as="h1">HAPPENINGS</Header>
        <FeaturedHappenings happening={initialHappenings[0]} />
        {/*<Header textAlign="center" as="h2">UPCOMING EVENTS</Header>*/}
        {/*<Grid columns={3} className="happenings-grid-container" stackable>*/}
        {/*{happenings.slice(0, 3).map(happening => (*/}
        {/*<Grid.Column>*/}
        {/*<HappeningItem happening={happening} height={200} />*/}
        {/*</Grid.Column>*/}
        {/*))}*/}
        {/*</Grid>*/}
        {/*<Divider className="happenings-divider"/>*/}
        <span>
            <Header className="gallery-filter-header">HAPPENING TYPE:</Header>
            <Dropdown
              className="gallery-filter-dropdown"
              name="printTypeFilter"
              onChange={this.onFilterChange}
              value={filterChoice}
              text={filterChoice.toUpperCase()}
              selection
              options={filterOptions}
            />
          </span>
        <Grid columns={4} className="happenings-grid-container" stackable>
          {currentHappenings.slice(rangeLow, rangeHigh).map(happening => (
            <Grid.Column>
              <HappeningItem happening={happening} height={150} />
            </Grid.Column>
          ))}
        </Grid>
        <div className="pagination-container">
          <button
            disabled={currentPage === 1}
            onClick={() => this.onPageChange("left")}
          >
            <Icon name="angle left" size="large" />
          </button>
          {currentPage}/{Math.ceil(currentHappenings.length / 8)}
          <button
            disabled={currentPage === Math.ceil(currentHappenings.length / 8)}
            onClick={() => this.onPageChange("right")}
          >
            <Icon name="angle right" size="large" />
          </button>
        </div>
      </Layout>
    );
  }
}


export const query = graphql`
  query {
    allAirtable(filter: {table: {eq: "Happenings"}}) {
      edges {
        node {
          data {
            Title
            HeroImage {
              url
            }
            HeroImageURL
            HappeningType
            OverlayColor
            Date
            Slug
          }
        }
      }
    }
  }
`;